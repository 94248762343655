import get from 'lodash/get';
import * as Prismic from '@prismicio/client';
import { PreviewData } from 'next';

import { getConfig } from 'utils/config';
import { findKeyByValue } from 'utils/lodash/client';
import {
  AlternateLanguageUrls,
  AlternateLanguagesSlug,
  DocumentWithAlternateLanguages,
} from './types';
import { NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING } from './consts';

export const normalizeHref = (href: string): string => {
  if (href.startsWith('https://#')) {
    return href.replace('https://#', '#');
  }

  if (href.startsWith('https:///#')) {
    return href.replace('https:///#', '#');
  }

  if (href.startsWith('https:///')) {
    return href.replace('https:///', '/');
  }

  return href;
};

export const makePrismicClient = (): ReturnType<typeof Prismic.createClient> =>
  Prismic.createClient(getConfig().serverRuntimeConfig.prismicProjectUrl);

export const tryGetRefFromPreviewData = (
  previewData: PreviewData,
): string | undefined => {
  if (!previewData || typeof previewData !== 'object') {
    return undefined;
  }

  return get(previewData, 'ref');
};

export const getAlternateLanguagesUrlsMap = async (
  docs: DocumentWithAlternateLanguages[],
  makeSlug: (url: string | undefined) => string | undefined = (
    url: string | undefined,
  ) => url,
  slugPath = 'data.slug',
): Promise<Partial<MappedObject<AlternateLanguagesSlug[]>>> => {
  const prismicClient = makePrismicClient();

  const alternateLanguageSlugsArray: AlternateLanguageUrls[] =
    await Promise.all(
      docs.map(({ id, alternateLanguages }) =>
        (async () => {
          const alternateDocs = await prismicClient.getAllByIDs(
            alternateLanguages.map((alternateLanguage) => alternateLanguage.id),
            {
              lang: '*',
            },
          );

          return {
            id,
            slugs: alternateDocs.map((alternateDoc) => ({
              lang:
                findKeyByValue(
                  NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING,
                  alternateDoc.lang,
                ) || '',
              slug: makeSlug(get(alternateDoc, slugPath)) || '/',
            })),
          };
        })(),
      ),
    );

  return alternateLanguageSlugsArray.reduce<
    Partial<MappedObject<AlternateLanguagesSlug[]>>
  >(
    (result, { id, slugs }) => ({
      ...result,
      [id]: slugs,
    }),
    {},
  );
};
